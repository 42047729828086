.App {
  text-align: center;
  font-family: Arial, sans-serif;
  background-color: rgb(33, 33, 51);
  color: white;
}

.movie-image-container {
  margin: 20px;
}

form {
  margin: 20px;
}

p {
  size: 25 px;
}

ol {
  list-style-type: none;
}