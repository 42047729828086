.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color:  rgb(33, 33, 51);
    position: relative;
  }
  
  .auth-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    color: #000;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 15px 15px 10px rgba(0, 0, 0, 0.5);
    width: 300px;
    text-align: center;
  }
  
  .auth-box input {
    display: block;
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .auth-box button {
    display: block;
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
  }
  
  .auth-box button:hover {
    background-color: #0056b3;
  }
  
  .error-popup {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffdddd;
    color: #d8000c;
    padding: 10px;
    border: 1px solid #d8000c;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }
  
  .error-popup p {
    margin: 0;
  }
  
  .error-popup button {
    margin-top: 10px;
    background-color: #d8000c;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .error-popup button:hover {
    background-color: #a80000;
  }
  