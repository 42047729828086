.movie-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 450px;
  overflow: hidden;
  margin: 20px auto;
  border: 2px solid #000;
  background: #fff; /* Ensure background is white to see the image */
}

canvas {
  width: 100%;
  height: 100%;
  image-rendering: pixelated;
}

.hidden-image {
  display: none;
}
